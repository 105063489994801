import scan_img from "../img/scan.svg";
import { useTranslation } from "react-i18next";

const DownloadButton = ({ desk, screen }) => {
    const { t } = useTranslation();
    return (
        <section className="download-section">
            {(screen === "desk") && (
            <div className="__download-btn">
                <h4 className="subtitle text-base">{t('pages.home.download_button.text')}</h4>
                <img src={scan_img} alt="" />
            </div>)}
        </section>
    );
};

export default DownloadButton;