import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";
import "./App.scss";
import Home from "./pages/home/Home";


function App() {
    const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<html lang={process.env.REACT_APP_LANGUAGE || 'sv'} />
				<title>{t('metadata.title')}</title>
			</Helmet>
			<Home />
		</>
	);
}

export default App;
