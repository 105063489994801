import React from "react";
import { useTranslation } from "react-i18next";
import apple from "../img/apple-en.png";
import google from "../img/google-en.png";

const faqs = [
	{
		key: "pricing",
	},
	{
		key: "for_whom",
	},
	{
		key: "only_elite",
	},
	{
		key: "science",
	},
	{
		key: "commitment",
	},
	{
		key: "training_plans",
	},
	{
		key: "what_if",
	},
];
const Faqs = ({screen}) => {
    const { t } = useTranslation();
	return (
		<section className="faqs-section">
			<div id="about-us" className="faq-wrapper">
				<div className="main-item">
					<h2 className="title">{t('pages.home.faq.about.title')}</h2>
					<div className="txt">
						{t('pages.home.faq.about.text')}
					</div>
				</div>
				<div id="faq" className="main-item">
					<h2 className="title">{t('pages.home.faq.faq_section.title')}</h2>
					{faqs &&
						faqs.map(({ key }, i) => (
							<div className="faq" key={i}>
								<h4 className="subtitle">{t('pages.home.faq.faq_section.' + key + '.title')}</h4>
								<div className="text">{t('pages.home.faq.faq_section.' + key + '.text')}</div>
							</div>
						))}
				</div>

				<div className="main-item">
					<h2 className="title">{t('pages.home.faq.contact.title')}</h2>
					<div className="txt">
						{t('pages.home.faq.contact.company_name')}
					</div>
					<div className="txt">
						{t('pages.home.faq.contact.address')}
					</div>
					<div className="txt">
						{t('pages.home.faq.contact.postal_code')}
					</div>
					<br />
					<div className="txt">
						<a href="mailto:hello@adv-x.se" className="text-base">hello@adv-x.se</a>
					</div>
				</div>

				{(screen === "tab" || screen === "mobile") && (
					<div className="__banner-btn">
						<a className="btn-download" href="/download">
							<img src={google} alt="" />
						</a>
						<a className="btn-download" href="/download">
							<img src={apple} alt="" />
						</a>
					</div>
				)}
			</div>
		</section>
	);
};

export default Faqs;
