import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import MuxPlayer from '@mux/mux-player-react/lazy';

import cancel from "../img/cancel.png";
import phone1 from "../img/phone-1.png";
import phone2 from "../img/phone-2.png";
import phone from "../img/phone-mobile.png";
import phoneWeb from "../img/phone-web.png";

const About = ({ screen }) => {
    const { t } = useTranslation();
	const [openPlayer, setOpenPlayer] = useState(false);
	const videoAbout = useRef(null);
	const attemptPlay = () => {
		videoAbout &&
		videoAbout.current &&
		videoAbout.current.play().catch(error => {
			console.error("Error attempting to play", error);
		  });
	  };
	return (
		<section className="about-section">
			{screen === "desk" && <img src={phone1} className="phone-1" alt="" />}
			{screen === "desk" && <img src={phone2} className="phone-2" alt="" />}
			{openPlayer && (
				<div className="__modal">
					<img
						src={cancel}
						className="cancel"
						onClick={() => setOpenPlayer(!openPlayer)}
						alt=""
					/>{" "}
					<div
						className="__modal-body-close"
						onClick={() => setOpenPlayer(!openPlayer)}
					></div>
					<div className="__modal-body">
						<MuxPlayer
							loading="viewport"
							playbackId="8eEVOq283wiTZwYo02La02ZkVAdzmU5H4iNonF8CM6ezw"
							metadata={{
							  video_id: 'advx-1',
							  video_title: 'Training',
							}}
							muted="true"
							streamType="on-demand"
							loop="true"
							ref={videoAbout}
							onLoadedData={ () => attemptPlay() }
						/>
					</div>
				</div>

			)}
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6 text-center text-lg-end __about-img">
						<img
							className="cursor-pointer"
							src={
								screen === "tab" || screen === "mobile"
									? phone
									: phoneWeb
							}
							alt=""
							onClick={() => setOpenPlayer(!openPlayer)}
						/>
					</div>
					<div className="col-lg-6 text-center text-lg-start ps-xl-5">
						<div className="section-content">
							<div className="cont">
								<h2 className="title">
									<span>{t('pages.home.about.title_pre')}</span>
									<span className="d-block text-base">{t('pages.home.about.title_post')}</span>
								</h2>
								<div className="text">
									{t('pages.home.about.text')}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default About;
