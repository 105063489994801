import React from "react";
import Header from "../../components/Header";
import About from "./components/About";
import Banner from "./components/Banner";
import DownloadButton from "./components/DownloadButton";
import Experience from "./components/Experience";
import Faqs from "./components/Faqs";
import MobileApk from "./components/MobileApk";
import Testimonial from "./components/Testimonial";
import { Science } from "./components/Science";
import LanguageSelector from "../../components/LanguageSelector";

const Home = () => {
	const [screen, setScreen] = React.useState("desk");
	const [desk, setDesk] = React.useState(true);
	const handleMobile = () => {
		const width = window.innerWidth;
		if (width < 767) {
			setScreen("mobile");
		} else if (width < 992) {
			setDesk(false);
			setScreen("tab");
		} else {
			setScreen("desk");
			setDesk(true);
		}
		return;
	};
	React.useEffect(() => {
		handleMobile();
		window.addEventListener("resize", handleMobile);
	}, []);
	return (
		<>
			<LanguageSelector />
			<Header />
			<DownloadButton screen={screen} />
			<Banner screen={screen} desk={desk} />
			<About screen={screen} />
			<Experience screen={screen} desk={desk} />
			<Testimonial />
			<MobileApk screen={screen} />
			<Science />
			<Faqs screen={screen} />
		</>
	);
};

export default Home;
