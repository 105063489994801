import React from "react";
import { useTranslation } from "react-i18next";
import desktop from "../img/erfenhet-desktop.png";
import mobile from "../img/erfenhet-mobile.png";
import tab from "../img/erfenhet-tab.png";
const Experience = ({ screen, desc }) => {
    const { t } = useTranslation();
	return (
		<>
			<div
				className="erfenhet-section"
				style={{
					background:
						screen === "desk"
							? `url(${desktop}) no-repeat center right / cover`
							: screen === "tab"
							? `url(${tab}) no-repeat top center / cover`
							: `url(${mobile}) no-repeat top center / cover`,
				}}
			>
				<div className="container">
					<div className="section-content">
						<h2 className="title">
							{t('pages.home.experience.title_pre')}
							<span className="text-base d-block">{t('pages.home.experience.title_post')}</span>
						</h2>
						<p>
							{t('pages.home.experience.text')}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Experience;
