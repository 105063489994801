import React from "react";
import { useTranslation } from "react-i18next";
import img from "../img/app2.png";
import apple from "../img/apple-en.png";
import google from "../img/google-en.png";

const MobileApk = ({screen}) => {
    const { t } = useTranslation();
	return (
		<section className="app-section overflow-hidden">
			<div className="container">
				<div className="app-wrapper">
					<div className="app-cont">
						<h2 className="title">{t('pages.home.mobile.title')}</h2>
						<div className="text">
							{t('pages.home.mobile.text')}
						</div>
						<a href="/download" className="btn-download text-base mt-3 d-inline-block">
							{t('pages.home.mobile.cta')}
						</a>
						{(screen === "tab" || screen === "mobile") && (
							<div className="__banner-btn">
								<a className="btn-download" href="/download">
									<img src={google} alt="" />
								</a>
								<a className="btn-download" href="/download">
									<img src={apple} alt="" />
								</a>
							</div>
						)}
					</div>
					<div className="app-img">
						<img src={img} className="mw-100" alt="" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default MobileApk;
