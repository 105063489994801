import React from "react";
import logo from "../assets/img/logo.svg";
const Header = () => {
	return (
		<header>
			<div className="container-fluid">
				<div className="d-flex flex-wrap justify-content-between">
					{/* <div className="d-lg-block">
						<ul className="menu">
							<li>
								<a href="#" className="active">
									Hem
								</a>
							</li>
							<li>
								<a href="/download">Registrera dig</a>
							</li>
							<li>
								<a href="#faq">FAQ</a>
							</li>
							<li>
								<a href="#about-us">Om oss</a>
							</li>
						</ul>
					</div> */}
					<a href="#" className="ms-auto">
						<img src={logo} alt="" />
					</a>
				</div>
			</div>
		</header>
	);
};

export default Header;
