import React from "react";
const LanguageSelector = () => {
	return (
		<section id="language-selector">
			<div className="container-fluid">
				<div className="d-flex flex-wrap justify-content-between">
					<ul className="menu">
						<li>
							<a href="/">Svenska</a>
						</li>
						<li>
							<a href="/en">English</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default LanguageSelector;
