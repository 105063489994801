import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import img1 from "../img/user1.png";
import img2 from "../img/user2.png";
import img3 from "../img/user3.png";

const data = [
	{
		key: 1,
		img: img1,
		text: `“Mitt individuella träningsprogram är en stark bidragande orsak till att jag lyckats nå världstoppen”`,
		addr: "Rebecca Peterson",
	},
	{
		key: 2,
		img: img2,
		text: `“Träningsplanerna gjorde mig redo att klara fystesterna till college i USA”`,
		addr: "Wilhelm Karlsson",
	},
	{
		key: 3,
		img: img3,
		text: `“Jag känner mig starkare och gladare med specifika fysplaner som hjälpt mig att spela bättre tennis och undvika eventuell skador”`,
		addr: "Rana Mostaghel",
	},
];
function SampleNextArrow(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<svg width="19" height="32" viewBox="0 0 19 32" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.70911 0.636383L17.5364 14.4636C18.3849 15.3121 18.3849 16.6879 17.5364 17.5364L3.7091 31.3636C2.86059 32.2121 1.48489 32.2121 0.636376 31.3636C-0.212133 30.5151 -0.212132 29.1394 0.636377 28.2909L12.9273 16L0.636381 3.70911C-0.212128 2.8606 -0.212128 1.48489 0.636382 0.636382C1.48489 -0.212127 2.8606 -0.212127 3.70911 0.636383Z"
					fill="black"
				/>
			</svg>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<svg width="19" height="32" viewBox="0 0 19 32" fill="none">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.6489 31.3636L0.821611 17.5364C-0.0268974 16.6879 -0.0268974 15.3121 0.821611 14.4636L14.6489 0.636383C15.4974 -0.212126 16.8731 -0.212126 17.7216 0.636383C18.5701 1.48489 18.5701 2.8606 17.7216 3.70911L5.4307 16L17.7216 28.2909C18.5701 29.1394 18.5701 30.5151 17.7216 31.3636C16.8731 32.2121 15.4974 32.2121 14.6489 31.3636Z"
					fill="black"
				/>
			</svg>
		</div>
	);
}
const Testimonial = () => {
    const { t } = useTranslation();
	const settings = {
		dots: true,
		arrow: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};
	return (
		<section className="testimonial-section">
			<div className="container">
				<div className="testimonial-slider">
					<Slider {...settings}>
						{data &&
							data.map(({ img, key }, i) => (
								<div className="__client-item" key={i}>
									<div className="__client-item-img">
										<img src={img} alt="" />
									</div>
									<blockquote>{t('pages.home.testimonial.' + key +'.text')}</blockquote>
									<h6 className="text-base subtitle">{t('pages.home.testimonial.' + key +'.addr')}</h6>
								</div>
							))}
					</Slider>
				</div>
			</div>
		</section>
	);
};

export default Testimonial;
