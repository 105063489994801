import React from "react";
import { useTranslation } from "react-i18next";
import apple from "../img/apple-en.png";
import google from "../img/google-en.png";

const Banner = ({ desk, screen }) => {
    const { t } = useTranslation();
	return (
		<div className="banner-section">
			<div className="container">
				<div className="banner-content">
					<h1 className="title">
						<span className="text-base">{t('pages.home.banner.title_pre')}</span> <br/>{t('pages.home.banner.title_post')}
					</h1>
					<p>
						{t('pages.home.banner.text')}
					</p>
					{(screen === "tab" || screen === "mobile") && (
						<div className="__banner-btn">
							<a className="btn-download" href="/download">
								<img src={google} alt="" />
							</a>
							<a className="btn-download" href="/download">
								<img src={apple} alt="" />
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Banner;
