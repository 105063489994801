import React from "react";
import { useTranslation } from "react-i18next";
import science from "../img/ventenskapen.png";
export const Science = () => {
    const { t } = useTranslation();
	return (
		<section className="vetenskapen-section">
			<div className="container-xl">
				<div className="vetenskapen-wrapper">
					<div className="section-content">
						<h2 className="title text-base">{t('pages.home.science.title')}</h2>
						<div className="txt">
							{t('pages.home.science.text')}
						</div>
						<div className="mt-2">{t('pages.home.science.cta')}</div>
					</div>
					<div className="ventenskapen-img">
						<img src={science} alt="" />
					</div>
				</div>
			</div>
		</section>
	);
};
